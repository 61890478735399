import React from "react";
import {Col, Row, Typography} from "antd";
import {blockEnum, ResourceIcon} from "@project/libs";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {CardBlock} from "../CardBlock";
import {useMeetings} from "../../contexts";


export function AvailableMeetings() {
    const meetings = useMeetings();
    const { t } = useTranslation();

    if (!meetings || meetings.length < 2) {
        return null;
    }

    return (
        <>
            <Typography.Title
                level={1}
                style={{textAlign: 'center', marginTop: '1rem', color: 'white'}}
                id="available-meetings"
            >
                {t('meetings')}
            </Typography.Title>
            <Row gutter={[16, 16]} style={{padding: '1rem'}} id="meetings-rows" align="center" justify="center">
                {meetings.map(m => (
                    <Col xs={24} sm={12} md={8} lg={8} key={m.name}>
                        <Link to={`/app/meeting/${m.name}/`}>
                            <CardBlock
                                className="meeting-available-card"
                                icon={ResourceIcon[blockEnum.WORKSHOP]}
                                text={
                                    <Typography.Title level={2}>{m.title}</Typography.Title>
                                }
                            />
                        </Link>
                    </Col>
                ))}
            </Row>
        </>
    )
}